//Modules
import { createContext, useState, useEffect } from "react";

//Utilities
import useFetch from "./useFetch";

const { REACT_APP_ENV,
        REACT_APP_WP_URL,
        REACT_APP_WP_URL_DEV,
        REACT_APP_WP_MENU, 
        REACT_APP_WP_MENU_FOOTER,  
        REACT_APP_WP_MENU_IPB, 
        REACT_APP_WP_MENU_REDIRECT,
        REACT_APP_WP_MENU_APPS,
        REACT_APP_IPBNEWS_EP, 
        REACT_APP_IPBNEWS_EVENT,
        REACT_APP_IPBNEWS_NEWS,
        REACT_APP_CACHE_API_ROOT,
        REACT_APP_CACHE_API_ENDPOINT,
        REACT_APP_CACHE_EP_MENU,
        REACT_APP_CACHE_API_DATA_ENDPOINT,
        REACT_APP_WP_EP_MEDIA } = process.env


export const menuContext = createContext()
export const mediaContext = createContext()
export const newsContext = createContext()
export const eventsContext = createContext()

const CACHE_API_URL = `${REACT_APP_CACHE_API_ROOT}${REACT_APP_CACHE_API_ENDPOINT}`

export const MenuContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [navItemsTotal, setNavItemsTotal] = useState({
        navItems: null,
        sobreIPB: null,
        menuFooter:null,
        redirect:null,
        menuApps:null
    })
    
    const abortNavItmes = new AbortController()
    const abortSobreIPB = new AbortController()
    const abortMenuFooter = new AbortController()
    const abortMenuRedirect = new AbortController()
    const abortMenuApps = new AbortController()
    
    let API_URL = ''

    REACT_APP_ENV === "production" ?
        API_URL = `${window.location.protocol}//${window.location.hostname}${REACT_APP_WP_URL}`:
        API_URL =  REACT_APP_WP_URL_DEV
    

    useEffect(() => {
        const getNavItems = async () => {                      
            const resNavItems = await fetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU}`, { signal: abortNavItmes.signal })
            const dataNavItems = await resNavItems.json()
            const resSobreIPB = await fetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_IPB}`, { signal: abortSobreIPB.signal })     
            const dataSobreIPB = await resSobreIPB.json()
            const resMenuFooter = await fetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_FOOTER}`, { signal: abortMenuFooter.signal })
            const dataMenuFooter = await resMenuFooter.json()
            const resMenuRedirect = await fetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_REDIRECT}`, { signal: abortMenuRedirect.signal })
            const dataMenuRedirect = await resMenuRedirect.json()
            const resMenuApps = await fetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_APPS}`, { signal: abortMenuApps.signal })
            const dataMenuApps = await resMenuApps.json()
            setLoading(false)
            setNavItemsTotal({
                navItems: dataNavItems,
                sobreIPB: dataSobreIPB,
                menuFooter: dataMenuFooter,
                redirect: dataMenuRedirect,
                topApps: dataMenuApps
             })  
        }
        
        getNavItems()
        
        return () => {
            abortNavItmes.abort()
            abortSobreIPB.abort()
            abortMenuFooter.abort()
            abortMenuRedirect.abort()
            abortMenuApps.abort()
        }
    }, [])

    return(
        <>
            <menuContext.Provider value={{loading, navItemsTotal, API_URL, CACHE_API_URL}}>
                {children}
            </menuContext.Provider>
        </>
    )
}

export const MediaContextProvider = ({ children }) => {

    const { data: media } = useFetch(`${CACHE_API_URL}${REACT_APP_CACHE_API_DATA_ENDPOINT}${REACT_APP_WP_EP_MEDIA}`)
    
    return(
        <>
            {media &&
                <mediaContext.Provider value={{media}}>
                    {children}
                </mediaContext.Provider>
            }
        </>
    )
}

export const NewsContextProvider = ({ children }) => {

    const { data: news, loading, isError } = useFetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_NEWS}`)   

    return(
        <>
            <newsContext.Provider value={{news, loading, isError}}>
                {children}
            </newsContext.Provider>
        </>
    )
}

export const EventsContextProvider = ({ children }) => {

    const { data: agendaItems, loading, isError } = useFetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_EVENT}`)
    
    return(
        <>
            <eventsContext.Provider value={{agendaItems, loading, isError}}>
                {children}
            </eventsContext.Provider>
        </>
    )
}