let getMenuLevels = {}

const getLevels = (items, level) => {
    if (!(`Level${level}` in getMenuLevels)) {
        getMenuLevels[`Level${level}`] = []
    }
    items.map((item) => {       
        getMenuLevels[`Level${level}`].push(item)
        item?.child_items && 
        getLevels(item.child_items, level+1)
    })
}

const useMenuLevels = (items) => {
    let level = 1
    
    getLevels(items, level)
    const menuLevels = getMenuLevels
    getMenuLevels = {}

    return { menuLevels }
}

export default useMenuLevels
