//Modules
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//Utils
import useMenuLevels from '../../utils/useMenuLevels'
import { menuContext } from '../../utils/UtilContext'

const { REACT_APP_WP_EP, REACT_APP_WP_EP_SEARCH_PAGES, REACT_APP_WP_EP_SEARCH_POSTS, REACT_APP_WP_ED_LANG, REACT_APP_WP_ED_LANG_EN } = process.env

function SearchSite({ filtroPesquisa, lang }) {
    const { navItemsTotal, API_URL } = useContext(menuContext)

    const [pesquisaSitePages, setPesquisaSitePages] = useState([])
    const [pesquisaSitePosts, setPesquisaSitePosts] = useState([])
    
    const { menuLevels: navItemsLevels } = useMenuLevels(navItemsTotal.navItems.items)
    const { menuLevels: sobreIPBLevels } = useMenuLevels(navItemsTotal.sobreIPB.items)
    const { menuLevels: menuFooterLevels } = useMenuLevels(navItemsTotal.menuFooter.items)
    
    let menuLevels = {};
   
    const allKeys = new Set([
        ...Object.keys(navItemsLevels),
        ...Object.keys(sobreIPBLevels),
        ...Object.keys(menuFooterLevels)
    ]);

    allKeys.forEach(key => {
        menuLevels[key] = (navItemsLevels[key] || []).concat(sobreIPBLevels[key] || []).concat(menuFooterLevels[key] || []);
    });

    let filterPageMenu = []
    let filterPostMenu = []

    const abortContPage = new AbortController()
    const abortContPost = new AbortController()
    
    const $ = window.$
    
    let url = ""

    const findParent = (to) => {
        Object.keys(menuLevels).forEach(key => {
            menuLevels[key].map((item) => {
                if (item.ID === parseInt(to.menu_item_parent)){
                url = `${item['slug_'+lang]}/${url}`
                findParent(item)
                }
                return null
            })
        });
    }

    const goToLink = (to) => {
        url = to['slug_'+lang]     
        findParent(to)
        url = `/${lang}/${url}`
        
        return url
    }

    useEffect(() => {
        const getData = async () => {  
            let resContentPages = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_PAGES}"${filtroPesquisa}"`, { signal: abortContPage.signal })            
            const dataContentPages = await resContentPages.json()
            setPesquisaSitePages(dataContentPages)  
        }
        filtroPesquisa !== "" && getData()   
        
        return () => abortContPage.abort()
    }, [filtroPesquisa])

    useEffect(() => {
        const getData = async () => {     
            let resContentPosts
            lang === "en" ? resContentPosts = await fetch(`${API_URL}${REACT_APP_WP_ED_LANG}${REACT_APP_WP_ED_LANG_EN}${filtroPesquisa}`, { signal: abortContPost.signal })
            : resContentPosts = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_POSTS}"${filtroPesquisa}"`, { signal: abortContPost.signal })
            const dataContentPosts = await resContentPosts.json()
            setPesquisaSitePosts(dataContentPosts)  
        }
        filtroPesquisa !== "" && getData()   
        
        return () => abortContPost.abort()
    }, [filtroPesquisa])

    filtroPesquisa !== "" &&
        pesquisaSitePages.map((page) => {
            Object.keys(menuLevels).forEach(key => {
                menuLevels[key].map((item) => {
                    (item.object === "page" && parseInt(item.object_id) === page.id) && filterPageMenu.push(item)
                    return null
                })
            });
            return null
        }) &&
        pesquisaSitePosts.map((post) => {
            Object.keys(menuLevels).forEach(key => {
                menuLevels[key].map((item) => {
                    (item.object === "category" && parseInt(item.object_id) === post.categories[0] && !filterPostMenu.includes(item)) && filterPostMenu.push(item)
                    return null
                })
                return null
            });
        })     

    return (
        <>
        {(filterPageMenu.length > 0 || filterPostMenu.length > 0) && <span className='titulo'>Site</span>}
        <div className='siteRes'>                                    
            {filtroPesquisa !== "" && 
            <>
                <ul>                                                                                       
                    {filterPageMenu.map((siteItem) => (                                                    
                        <li key={siteItem.ID}>                     
                            <Link to={goToLink(siteItem)} onClick={()=>$('#searchModal').modal('hide')}>
                                {siteItem['title_'+lang]}
                            </Link>
                            <div dangerouslySetInnerHTML={{__html:pesquisaSitePages.find(function (page) {
                                                            if (page.id === parseInt(siteItem.object_id)) return page
                                                            return null
                                                        })?.excerpt.rendered.substring(0,100).concat("...")}}>                                                                    
                            </div>
                        </li>
                    ))}
                </ul>           
                <ul>                                        
                    {filterPostMenu.map((siteItem) => (
                        <li key={siteItem.ID}>
                            <Link to={goToLink(siteItem)} onClick={()=>$('#searchModal').modal('hide')}>
                                {siteItem['title_'+lang]}
                            </Link>
                            <div dangerouslySetInnerHTML={{__html:pesquisaSitePosts.find(function (post) {
                                                            if (post.categories[0] === parseInt(siteItem.object_id)) return post
                                                            return null
                                                        })?.excerpt.rendered.substring(0,100).concat("...")}}>                                                                    
                            </div>
                        </li>
                    ))}
                </ul>
            </>}
        </div>
        </>
    )
}

export default SearchSite
