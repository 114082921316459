//Modules
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

//Utils
import { menuContext, mediaContext } from '../../utils/UtilContext'
import useReturnMenuLevel from '../../utils/useReturnMenuLevel'

//Assets
import logo from '../../../img/logo-header.png'

const Nav = ({ dest, content, subItem }) => {
    const [logoHeader, setLogoHeader] = useState(logo)
    const { media } = useContext(mediaContext)
    const hoverMenu = useRef("")
    const { navItemsTotal } = useContext(menuContext)
    const abortCont = new AbortController()
    const $ = window.$

    let history = useLocation()
    const lang = history.pathname.substring(1,3)

    let menuLevel = useReturnMenuLevel(content)

    useEffect(() => {
        const logoUrl = media.filter((media) => media.slug === 'logo_header')
        logoUrl.length !== 0 && setLogoHeader(logoUrl[0].source_url)
        
        return () => abortCont.abort()
    }, [])

    useEffect(() => {        
        if (dest) {
            if (menuLevel === 2) {
                document.getElementById(dest)?.classList.add('mActive')
                document.getElementById(`${subItem.slug}_${subItem.ID}`)?.previousSibling.classList.add('mActive')
            }else{                    
                document.getElementById(dest)?.previousSibling.classList.add('mActive')
            }      
        }
        
        return () => {
            if (dest) {
                if (menuLevel === 2) {
                    document.getElementById(dest)?.classList.remove('mActive')
                    document.getElementById(`${subItem.slug}_${subItem.ID}`)?.previousSibling.classList.remove('mActive')
                }else{                    
                    document.getElementById(dest)?.previousSibling.classList.remove('mActive')
                }      
            }
        }
    }, [dest])

    const showMenu = (id) => {
        $(hoverMenu.current).css('visibility', 'hidden')
        $(hoverMenu.current).css('opacity', '0')
        hoverMenu.current = `#${id}.navMenu.secondLevel`
        $(hoverMenu.current).css('visibility', 'visible')
        $(hoverMenu.current).css('opacity', '1')
        $('.pageOverlay').css('opacity', '0.5')
    }
    const hideMenu = () => {
        $(hoverMenu.current).css('visibility', 'hidden')
        $(hoverMenu.current).css('opacity', '0')
        $('.pageOverlay').css('opacity', '0')
    }

    return(      
        <nav className="navbar topNav">
            <div className="container">
                <Link className="navbar-brand" to={"/"+lang}>
                    <img src={logoHeader} alt="Logotipo" />
                </Link>                
                <div className="navMenus">
                    <ul className="navMenu firstLevel" onMouseLeave={() => hideMenu()}>
                    {navItemsTotal.navItems.items.map((item) =>(
                        <li key={item.ID} className="navMenu-item" onMouseEnter={() => showMenu(`${item.slug}_${item.ID}`)}>
                            <Link to={`/${lang}/${item['slug_'+lang]}`} onClick={()=>hideMenu()}>
                            <div className="" dangerouslySetInnerHTML={{__html:item['title_'+lang]}}></div>
                            </Link>
                            <ul id={`${item.slug}_${item.ID}`} className="navMenu secondLevel">
                                <div className="container">
                                    {item?.child_items && item.child_items.map((child) => (                                                                
                                        <li key={child.ID} className="navMenu-item">
                                            <Link to={`/${lang}/${item['slug_'+lang]}/${child['slug_'+lang]}`} onClick={()=>hideMenu()}>
                                                <div id={`${child['slug_'+lang]}_${child.ID}`} dangerouslySetInnerHTML={{__html:child['title_'+lang]}}></div>
                                            </Link>
                                            <ul className="navMenu thirdLevel">
                                                {child?.child_items && child.child_items.map((subChild, index) => (
                                                    <li key={subChild.ID} className="navMenu-item">         
                                                        {
                                                        // index > 6 ? 
                                                        // <Link to={`/${item.slug}/${child.slug}`} id={`${subChild.slug}_${subChild.ID}`} onClick={()=>hideMenu()}>
                                                        //     <span>Ver mais...</span>                                                            
                                                        // </Link>
                                                        // :
                                                        subChild.object === "custom" ?
                                                            <a href={subChild.url} target="_blank" rel="noreferrer" id={`${subChild['slug_'+lang]}_${subChild.ID}`}>                                                                
                                                                {!subChild.acf.hide_title && <div dangerouslySetInnerHTML={{__html:subChild['title_'+lang]}}></div>}
                                                                {subChild?.acf && (subChild.acf?.menu_image && <img src={subChild.acf.menu_image} alt="imgMenu" />)}
                                                            </a>
                                                        :
                                                            <Link to={`/${lang}/${item['slug_'+lang]}/${child['slug_'+lang]}/${subChild['slug_'+lang]}`} id={`${subChild['slug_'+lang]}_${subChild.ID}`} onClick={()=>hideMenu()}>
                                                                {!subChild?.acf.hide_title && <div dangerouslySetInnerHTML={{__html:subChild['title_'+lang]}}></div>}
                                                                {subChild?.acf && (subChild.acf?.menu_image && <img src={subChild.acf.menu_image} alt="imgMenu" />)}
                                                            </Link>}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </div>
                            </ul>                   
                        </li>
                    ))}            
                    </ul>              
                </div>            
            </div>
        </nav>
    )

}

export default Nav