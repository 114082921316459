//Modules
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Utils
import useFetchXML from '../../utils/useFetchXML'

//Components
import PlanoEstudos from './widgets/PlanoEstudos'
import MaisInfo from './widgets/MaisInfo'

const { REACT_APP_GUIAECTS_CURSOS, REACT_APP_GUIAECTS_CURSO_DETALHE, REACT_APP_GUIAECTS_PT } = process.env

function CursoDetalhe({ slugCurso }) {
    const codCurso = slugCurso.split("-")

    const { data: cursoDetalhe } = useFetchXML(`${REACT_APP_GUIAECTS_CURSO_DETALHE}${REACT_APP_GUIAECTS_PT}&cod_escola=${codCurso[codCurso.length-2]}&cod_curso=${codCurso[codCurso.length-1]}`)
    const { data: listaCursos } = useFetchXML(`${REACT_APP_GUIAECTS_CURSOS}${REACT_APP_GUIAECTS_PT}`)
    const [detalhe, setDetalhe] = useState(null)
    const [curso, setCurso] = useState(null)

    let navigate = useNavigate()

    useEffect(() => {
        cursoDetalhe && setDetalhe(cursoDetalhe.ipb.escola[0].curso[0])
        if (listaCursos) {
            let cursoFilter = listaCursos.ipb.curso.filter((curso) => {
                if (curso.$.cod_curso === codCurso[codCurso.length-1]) {
                    return curso.$
                }else{
                    return false
                }
            })
            cursoFilter.length > 0 && setCurso(cursoFilter[0].$)
        }
    },[cursoDetalhe, listaCursos, codCurso])    

    if (curso) {
        var titulo
        switch(curso.tipo){
            case "C":
                titulo = "CTeSP"
            break;
            case "T":
                titulo = "CTeSP"
            break;
            case "L":
                titulo = "Licenciatura"
            break;
            case "PG":
                titulo = "Pós-Graduação"
            break;
            case "M":
                titulo = "Mestrado"
            break;
    
            default:
        }
    }

  return (
    <>
       { (detalhe && curso) ?
        <>
            <div className="container page title">
                <div className="row">
                    <div className="col-md-3">
                        <h3>{titulo}</h3>                            
                    </div>                    
                </div>
            </div>

            <section id={codCurso[codCurso.length-1]} className="course-detail">
                <article className='my-5'>
                    <div className="container page">
                        <div className="row">
                            <div className="col-md-3">                                
                                <button onClick={() => navigate(-1)}><span className="material-icons-outlined course-back">arrow_back</span></button>
                            </div>                    
                            <div className="col-md-9">
                                <div className="position-relative">
                                    <p className='course-title-detail'><strong>{curso.nome}</strong></p>
                                    {detalhe?.academic_authorities[0] !== "" &&
                                        <p><strong>Diretor(a) de Curso: </strong>{detalhe.academic_authorities[0].authority[0].$.name} [<a href={`mailto:${detalhe.academic_authorities[0].authority[0].$.login}@ipb.pt`}>{`${detalhe.academic_authorities[0].authority[0].$.login}@ipb.pt`}</a>]</p>
                                    }
                                </div>                                        
                            </div>
                        </div>
                    </div>
                </article>
                <MaisInfo detalhe={detalhe} />
                {detalhe.planos[0].plano.map((plano, index) => (
                    <PlanoEstudos key={index} plano={plano} index={index} planoCount={detalhe.planos[0].plano.length} />
                ))}
            </section>
        </>
        :<>
            <div className="container page title">
                <div className="row">
                    <div className="col-md-6">
                        <h3>Curso não encontrado</h3> 
                         <div className='course-detail'>
                            <button onClick={() => navigate(-1)}><span className="material-icons-outlined course-back">arrow_back</span></button>                           
                        </div>
                    </div>                    
                </div>
            </div>
        </>} 
    </> 
  )
}

export default CursoDetalhe